body{
    font-family: Nunito;
}
.homeBackground {
    background: url(../../assets/images/home.png);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    position: relative;
}


.homeHeader{
    display: flex;
    height:auto ;
    flex-direction:row;
    background-color:#EDBA37;
    justify-content:end;
    align-items:center;
     padding-left: 3%;
     padding-right: 5%;
}

.siteHeader{
    display: flex;
    height:auto ;
    flex-direction:row;
    background-color:#EDBA37;
    justify-content:end;
    align-items:center;
     padding-left: 3%;
     padding-right: 5%;
}
.mainHeader{
    position: absolute;
    background-color: transparent;
    padding: 30px 0px;
    width: 100%;
    z-index: 1;
}

.bg-orange {
    background-color: #ecba37;
}
.sub-box {
    display: flex;
    justify-content: flex-start;
    background-color: #FEF8EC;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 100px;
    padding: 15px 20px;
    margin: 15px 0px;
    border: 2px solid #B87B2A;
    /* width: 100%; */
    flex-wrap: nowrap;
    align-items: center;
}
#sec-con {
  
}

.sub-box .imges {
  width: 80px;
  height: 80px;
}

.sub-box .imges img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.sub-con {
    margin-left: 10px;
    width: 100%;
}
.sub-con h6 {
    position: relative;
    margin: 0px;
    margin-bottom: 5px;
    font-size: 18px;
    color: #B87B2A;
    font-weight: normal;
}
.sub-con p {
    font-size: 14px;
    margin: 0px;
}

.sub-box-3 .imges-3{
    width: 50px;
}
.sub-box-3 h4{
    font-size: 20px;
    margin-top: 5px;
}
.sub-box-3 p{
    font-size: 14px;
}
.sub-box-3{
   
    padding: 20px;
    border: 2px solid #ecba37;
    border-radius: 20px;
    margin: 10px;
}
.sub-box-3:hover{
background-color: #ecba37;
color:#000;
}
.text-center{
    text-align: center;
}
.sec-3-head{
    margin-bottom: 25px;
    margin-top: 50px !important;
    text-align: center;
    font-size: 25px;
}
.why-chooseus{
    position: relative;
    overflow: hidden;
    padding: 40px 0px;
}
.perfect-ride{
    position: relative;
    overflow: hidden;
    padding: 40px 0px;
    z-index: 1;
}
.bg-secondary{
    background-color: #b77a2a;
    color: #fff;
  
}
.empty-back {
    position: absolute;
    right: 0;
    top: 0px;
    width: 700px;
    height: 420px;
    transform: rotateZ(115deg);
    background: #ecba37;
}
.pre_head{
    font-size: 20px;
    color: #ecba37;
    margin: 0;
}
.section_head{
    font-size: 32px;
    margin: 0;
    margin-bottom: 10px;

}
.car-img {
    position: relative;
    height: 240px;
    position: relative;
    margin-top: 25px;
}
.car-img img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@media screen and ( max-width:480px){

    .heading{
        font-size: 22px;
    margin-top: 80px;
    }

    .empty-back{
        z-index: -1;
        top: auto;
        bottom: 0px;
        right: -20%;
        left: auto;
        transform: rotateZ(157deg);
        height: 255px;
    }
}