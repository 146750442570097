.sumheading
{
    font-size: 65px;
    padding-left: 1em;
    color: #333333;
    display: flex ;
    font-family: 'Nunito' !important;
    font-weight: bold;
    text-decoration: underline #FFC300;
    text-underline-position: under;
    
}


.dotted {
    border-bottom: 1px dotted red;
    height: 11px;
    position: absolute;
}

.loctext p
{
    color: #333333 !important;
    font-size: 14px !important;
    font-family: 'Nunito' !important;
    font-weight: bold;
}
.loctext p .listitem_value{
    color: #333333 !important;
  
}
.mapText
{
    font-size: 12px;
    font-family: 'Nunito';
    font-weight: bold;
    color: #fff;
    padding-left: 20px;
    //padding-right: 20px;
}
.loctextbold
{
    font-family: 'Nunito'!important;
    font-weight: bold !important;
    color: #333333;
    font-size: 17px !important;
}
.timelinetext {
    font-family: 'Nunito'!important;
    font-weight: bold !important;
    color: #333333;
    font-size: 14px !important;
}

.timelinesubtext {
    font-family: 'Nunito'!important;
    font-weight: 400 !important;
    color: #333333;
    font-size: 12px !important;
}

.addtext

{
    color: #333333 !important;
    font-size: 16px !important;
    font-family: 'Nunito' !important;
    font-weight: bold !important;
    padding-top: 5px;
}
.descriptionText
{
   color: #333333;
   font-size: 12px !important;
   font-family: 'Nunito' !important; 
   font-weight: 200 !important;
}

.pricetext
{
    font-size: 25px !important;
    color: #333333;
    font-family: 'Nunito' !important;
    font-weight: bold;
    font-weight: bold !important;
}

.pricetexttime
{
    font-size: 25px !important;
    color: #333333;
    font-family: 'Nunito' !important;
    font-weight: bold !important;
}

.fontText {
    font-family: 'Nunito'!important;
}
.nameText
{
    font-size: 16px !important;
    color: #333333;
    font-family: 'Nunito'!important;
    font-weight: bold !important;
    letter-spacing: 1.75px;

}
.classText
{
    font-family: 'Nunito'!important;
    // font-weight: 600 !important;
    color: #333333;
    font-size: 14px !important;
}
.capacity
{
    font-family: 'Nunito'!important;
    color: #333333;
    font-size: 20px !important;
    //padding-right: 1em;
}
.capacityCount
{
    font-family: 'Nunito'!important;
    color: #333333;
    font-size: 20px !important;
    opacity: 50%;
}
.modalHeader
{
    font-family: 'Nunito' !important;
    font-size: 20px !important;
    font-weight: bold !important;
    //margin-left: 30% !important;
}

.titleText{
    font-family: 'Nunito' !important;
    font-size: 16px !important;
    font-weight: bold !important;
}



.buttonText
{
    font-family: 'Nunito'!important;
    font-weight: bold !important;
    font-size: 12px !important;
    color: #FFFFFF;
}

.guestcheckout
{
    font-family: 'Nunito'!important;
    font-weight: bold !important;
    font-size: 14px !important;
    color: #FFFFFF;
}

.pac-container { z-index: 100000; }
.MuiAccordion-root:before
{
display: none !important;
}
.vertical
{
    
    border-left:'1px solid red',
    height='3em',
margin-left='2.1em'
}

.MuiButton-filledSizeSmall {
    min-width: none !important;
}


::file-selector-button {
    display: none;
}

.text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
            line-clamp: 2; 
    -webkit-box-orient: vertical;
 }