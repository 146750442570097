.successText{
    font-family: 'Nunito'!important;
    font-weight: bold !important;
    color: #333333;
    font-size: 14px !important;
    text-transform: none !important;
}

.thanks_msg{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    overflow: hidden;
    padding: 15px 15px !important;
    border: 1px solid #ddd;
    border-radius: 10px;
    width: 80%;
    margin: 20px auto;
    flex-wrap: wrap;
}

.tnks_msg_head{
    color: green;
    font-size: 32px;
    margin-top: 0px;
}