
.loginBackground {
    background: url(../../assets/images/logi.jpg);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
}

.login {
    background: white;
    padding: 20px;
    box-shadow: 0 0 15px 0px #ababab;
    border-radius: 20px;
    margin: auto;
    margin-top: -10%;
}

.loginheading
{
    font-size: 22px;
    color: #333333;
    display: flex ;
    font-weight:700;
    font-family: 'Nunito' !important;
    font-weight: bold;
    padding-bottom: 0px !important;
}

.login_rightsec{
    flex-wrap: wrap;
    place-content: space-around;
    display: flex;
    align-content: space-around;
    justify-content: space-around;
}
button.btn_login{
    background-color: #EDBA37;
    border-radius: 6px;
    padding: 10px 15px;
    margin: 0px;
}
button.btn_google_login{
    background-color: rgb(66, 134, 245);
    border-radius: 6px;
    padding: 10px 15px;
    margin: 0px;
}
.forgotText
{
    font-size: 14px !important;
    color: '#333333';
    font-weight: 700;
    font-family: 'Nunito';
}
.loginButton
{
    font-family: 'Nunito';
    // font-weight: bold !important;
    font-size: 12px !important;
    color: #FFFFFF;
    text-transform: none !important;
}
.accountText
{
    //font-weight: 600 !important;
    font-size: 13px !important;
    font-family: 'Nunito';
}
.googleButton
{
    font-family: 'Nunito' !important;
    font-weight: bold;
    font-size: 12px !important;
    color: #FFFFFF;
    text-transform: none !important;
}